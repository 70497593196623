import { a } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'
import './skills.scss'
import './projects.scss'
import { useEffect, useRef, useState } from 'react'
import { skills } from './constants.js'

export default function Skills({
  clickedToSkills,
  setClickedToSkills,
  anyClick,
  setAnyClick,
  spring,
  findClickedObject,
  start,
}) {
  const skillsScene = useGLTF('/models/skills-pc.glb')

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleCarouselNext = () => {
    const newSlide = currentSlide + 1

    if (newSlide < maxSlides) {
      setCurrentSlide(newSlide)
    }
  }

  const handleCarouselPrev = () => {
    const newSlide = currentSlide - 1

    if (newSlide >= 0) {
      setCurrentSlide(newSlide)
    }
  }

  const maxSlides = skills.length

  const startIndex = currentSlide
  const endIndex = startIndex + 1

  return (
    <>
      <a.group
        scale={spring.scaleToSkills}
        position={spring.positionToSkills}
        rotation={spring.rotationToSkills}
      >
        <primitive
          object={skillsScene.scene}
          userData={{ viewPos: [-4.24, 2.2, 0] }}
          onClick={(e) => {
            e.stopPropagation()

            // Traverse the object hierarchy to find the clicked object
            const clickedObject = findClickedObject(e.object)

            if (!anyClick) {
              if (skillsScene.scene === clickedObject) {
                setClickedToSkills(clickedObject)
                setAnyClick(true)
              } else {
                setClickedToSkills(null)
                setAnyClick(false)
              }
            }
          }}
        >
          {clickedToSkills && (
            <Html
              transform
              wrapperClass='htmlScreen'
              distanceFactor={1.17}
              //do the same for contact position
              position={[
                -4.44088888168335, 1.914636492729187, -3.9675824642181396,
              ]}
            >
              <div className='skills'>
                <h2 className='skills-header px-1'>Gear</h2>
                <div className='carousel-container'>
                  {skills
                    .slice(startIndex, endIndex)
                    .map((categoryData, categoryIndex) => (
                      <div
                        className='flex column mb-1 hoverable blue'
                        key={categoryIndex}
                      >
                        <div className='glow text flex row justify-space-between height-text'>
                          <strong>{categoryData.category}</strong>
                        </div>
                        <div className='glow flex column p-1 pt-2'>
                          {categoryData.skills.map((skill, skillIndex) => {
                            return (
                              <div
                                key={skillIndex}
                                className='flex row bar-one bar-con'
                              >
                                <div
                                  className='bar'
                                  style={{
                                    width: `${parseFloat(skill.level)}%`,
                                  }}
                                >
                                  <strong className='skill-name'>
                                    {skill.skill}
                                  </strong>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    ))}
                </div>
                <div className='carousel-controlsP float-right mb-2 mr-1'>
                  <div
                    className={`carousel-control-btnP hamburgerP arrow-previous mr-1 ml-1 ${
                      currentSlide === 0 ? 'disabled' : ''
                    }`}
                    disabled={currentSlide === 0}
                    onClick={handleCarouselPrev}
                  >
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                  </div>

                  <div
                    className={`carousel-control-btnP hamburgerP arrow-next ${
                      currentSlide === maxSlides - 1 ? 'disabled' : ''
                    }`}
                    disabled={currentSlide === maxSlides - 1}
                    onClick={handleCarouselNext}
                  >
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                  </div>
                </div>
                <button
                  className='hover-flicker-1 btn-projects'
                  onClick={() => {
                    setClickedToSkills(null)
                    setAnyClick(false)
                  }}
                >
                  Back
                </button>
              </div>
            </Html>
          )}
        </primitive>
      </a.group>
      {!anyClick && (
        <Html
          transform
          wrapperClass='tag'
          distanceFactor={1.17}
          position={[-4.440887451171875, 2.335966944694519, -3.96]}
        >
          <div
            className={`tag-label ${start ? 'show-smoothly' : ''}`}
            onClick={() => {
              setClickedToSkills(skillsScene.scene)
              setAnyClick(true)
            }}
          >
            Skills
          </div>
        </Html>
      )}
    </>
  )
}
