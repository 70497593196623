import { a } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'
import './skills.scss'
import './credits.scss'
import { useState } from 'react'
import { credits } from './constants'

export default function Credits({
  clickedToCredits,
  setClickedToCredits,
  anyClick,
  setAnyClick,
  spring,
  findClickedObject,
  start,
}) {
  const creditsScene = useGLTF('/models/credits-pc.glb')

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleCarouselNext = () => {
    const newSlide = currentSlide + 1

    if (newSlide < maxSlides) {
      setCurrentSlide(newSlide)
    }
  }

  const handleCarouselPrev = () => {
    const newSlide = currentSlide - 1

    if (newSlide >= 0) {
      setCurrentSlide(newSlide)
    }
  }

  const maxSlides = Math.ceil(credits.length)

  const startIndex = currentSlide
  const endIndex = startIndex + 1

  return (
    <>
      <a.group
        scale={spring.scaleToCredits}
        position={spring.positionToCredits}
        rotation={spring.rotationToCredits}
      >
        <primitive
          object={creditsScene.scene}
          userData={{ viewPos: [-6.96, 2.72, 0] }}
          onClick={(e) => {
            e.stopPropagation()
            // Traverse the object hierarchy to find the clicked object
            const clickedObject = findClickedObject(e.object)

            if (!anyClick) {
              if (creditsScene.scene === clickedObject) {
                setClickedToCredits(clickedObject)
                setAnyClick(true)
              } else {
                setClickedToCredits(null)
                setAnyClick(false)
              }
            }
          }}
        >
          {clickedToCredits && (
            <Html
              transform
              wrapperClass='htmlScreen'
              distanceFactor={1.17}
              position={[-6.135871078491211, 2.285, -4.280086187744141]}
            >
              <div className='credits '>
                <h2 className='credits-header'>Credits</h2>
                <div className='carousel-container'>
                  {credits
                    .slice(startIndex, endIndex)
                    .map((credit, creditIndex) => (
                      <div
                        key={credit.id}
                        className={`flex column mb-1 hoverable ${credit.color}`}
                      >
                        <div className='glow flex column p-1 pb-2'>
                          <div className='flex row justify-space-between flex-wrap'>
                            <div className='flex column'>
                              <strong>{credit.task}</strong>
                              <span className='glow text'>{credit.name}</span>
                            </div>
                          </div>
                          <div className='flex column px-1'>
                            <a href={credit.link} target='_blank'>
                              <button className='button-glow blue mt-1'>
                                {credit.buttonText}
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className='carousel-controlsCr float-right '>
                  <div
                    className={`carousel-control-btnCr hamburgerCr arrow-previous mr-1 ml-1 ${
                      currentSlide === 0 ? 'disabled' : ''
                    }`}
                    disabled={currentSlide === 0}
                    onClick={handleCarouselPrev}
                  >
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                  </div>

                  <div
                    className={`carousel-control-btnCr hamburgerCr arrow-next ${
                      currentSlide === maxSlides - 1 ? 'disabled' : ''
                    }`}
                    disabled={currentSlide === maxSlides - 1}
                    onClick={handleCarouselNext}
                  >
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                  </div>
                </div>
                <button
                  className='button-glow green'
                  onClick={() => {
                    setClickedToCredits(null)
                    setAnyClick(false)
                  }}
                >
                  Back
                </button>
              </div>
            </Html>
          )}
        </primitive>
      </a.group>
      {!anyClick && (
        <Html
          // occlude='blending'
          transform
          wrapperClass='tag'
          distanceFactor={1.17}
          position={[-6.133871078491211, 2.83, -4.280086187744141]}
        >
          <div
            className={`tag-label ${start ? 'show-smoothly' : ''}`}
            onClick={() => {
              setClickedToCredits(creditsScene.scene)
              setAnyClick(true)
            }}
          >
            Credits
          </div>
        </Html>
      )}
    </>
  )
}
