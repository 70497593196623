import { useProgress } from '@react-three/drei'

export const LoadingScreen = ({
  started,
  onStarted,
  audioLoaded,
  musicLoaded,
}) => {
  const { progress } = useProgress()

  return (
    <div className={`loadingScreen ${started ? 'loadingScreen--started' : ''}`}>
      <div className='loadingScreen__progress'>
        <div
          className='loadingScreen__progress__value'
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
      <div className='loadingScreen__board'>
        <h1 className='loadingScreen__title'>GHOSTWIELD</h1>
        <button
          className='loadingScreen__button'
          disabled={progress < 100 || !audioLoaded || !musicLoaded}
          onClick={onStarted}
        >
          Start
        </button>
      </div>
    </div>
  )
}
