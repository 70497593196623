import { a } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'

export default function About({
  clickedToAbout,
  setClickedToAbout,
  anyClick,
  setAnyClick,
  findClickedObject,
  start,
}) {
  const about = useGLTF('/models/tv.glb')

  return (
    <>
      <a.group>
        <primitive
          object={about.scene}
          userData={{
            viewPos: [4, 2.5, -11.5],
          }}
          onClick={(e) => {
            e.stopPropagation()
            // Traverse the object hierarchy to find the clicked object
            const clickedObject = findClickedObject(e.object)

            if (!anyClick) {
              if (about.scene === clickedObject) {
                setClickedToAbout(clickedObject)
                setAnyClick(true)
              } else {
                setClickedToAbout(null)
                setAnyClick(false)
              }
            }
          }}
        >
          {clickedToAbout && (
            <Html
              transform
              wrapperClass='htmlScreen'
              distanceFactor={1.17}
              position={[3.89, 2.586227989196777, -11.43255271911621]}
              rotation-y={-0.127}
            >
              <div className='about flex row w-100 ml-2 px-1'>
                <div className='column w-46 p-1 pl-5 pr-4'>
                  <h2 className='play-once'>ATTRIBUTES</h2>
                  <div className='flex row w-100 pt-1 center-children'>
                    <div className='avatar bottom'>
                      <img src='./avatar.png' alt='Badr Afilal' />
                    </div>
                  </div>
                  <div className='row w-100 pt-1'>
                    <div className='field w-38'>
                      <label className='glow text medium-text'>Name</label>
                      <input
                        className='medium-text'
                        readOnly
                        disabled
                        type='text'
                        value='Badr Afilal'
                        pattern='\w+'
                      />
                    </div>
                    <div className='field w-58 medium-text'>
                      <label className='glow text'>Title</label>
                      <select>
                        <option value='cyber-consultant'>
                          Security Analyst
                        </option>
                        <option value='cyber-consultant'>
                          Cyber Consultant
                        </option>
                        <option value='pentester'>Pentester</option>
                      </select>
                    </div>
                  </div>
                  <div className='row w-100'>
                    <div className='field w-31'>
                      <label className='glow text medium-text'>Sex</label>
                      <input
                        className='medium-text'
                        readOnly
                        disabled
                        value='Male'
                      />
                    </div>
                    <div className='field w-33'>
                      <label className='glow text medium-text'>Origin</label>
                      <input
                        className='medium-text'
                        readOnly
                        disabled
                        value='Morocco'
                      />
                    </div>
                    <div className='field w-32' data-unit='Yrs'>
                      <label className='glow text medium-text'>Age</label>
                      <input
                        className='medium-text'
                        readOnly
                        disabled
                        value='23'
                        type='number'
                      />
                    </div>
                  </div>
                  <h2 className='pt-3'></h2>
                </div>

                <div className='column w-49 p-1'>
                  <h2 className='play-once'>BIOGRAPHY</h2>
                  <div className='row w-100 pt-1'>
                    <label className='glow text medium-text'>
                      Early Beginnings
                    </label>
                    <textarea
                      className='medium-text'
                      defaultValue='At the age of 15, Badr first came into contact with the world of technology and programming.'
                      disabled
                      readOnly
                      name='story'
                      rows='2'
                      cols='65'
                    ></textarea>
                  </div>
                  <div className='row w-100 pt-1'>
                    <label className='glow text to-right medium-text'>
                      College Years
                    </label>
                    <textarea
                      className='medium-text'
                      defaultValue='Throughout his college years, Badr developed a deep passion for cybersecurity, which has become a driving force in his career.'
                      disabled
                      readOnly
                      name='story'
                      rows='3'
                      cols='65'
                    ></textarea>
                  </div>
                  <div className='row w-100 pt-1'>
                    <label className='glow text medium-text'>
                      Professional Journey
                    </label>
                    <textarea
                      className='medium-text'
                      defaultValue='Security Analyst Badr detects and responds to cyber threats using a variety of platforms, such as ELK, QRadar, LogRhythm, Fortisiem, and The Hive..'
                      disabled
                      readOnly
                      name='story'
                      rows='3'
                      cols='65'
                    ></textarea>
                  </div>
                  <button
                    className='hover-flicker-1 green btn-skew mt-1'
                    onClick={() => {
                      setClickedToAbout(null)
                      setAnyClick(false)
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </Html>
          )}
          {!anyClick && (
            <Html
              transform
              wrapperClass='tag'
              distanceFactor={1.17}
              position={[
                3.9449615478515625, 3.736227989196777, -11.405017852783203,
              ]}
            >
              <div
                className={`tag-label ${start ? 'show-smoothly' : ''}`}
                onClick={() => {
                  setClickedToAbout(about.scene)
                  setAnyClick(true)
                }}
              >
                About
              </div>
            </Html>
          )}
        </primitive>
      </a.group>
    </>
  )
}
