import { a } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'
import { projects } from './constants'
import { useState } from 'react'
import './projects.scss'

export default function Bounties({
  clickedToProjects,
  setClickedToProjects,
  anyClick,
  setAnyClick,
  spring,
  findClickedObject,
  start,
}) {
  const projectsScene = useGLTF('/models/projects-pc.glb')

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleCarouselNext = () => {
    const newSlide = currentSlide + 1

    if (newSlide < maxSlides) {
      setCurrentSlide(newSlide)
    }
  }

  const handleCarouselPrev = () => {
    const newSlide = currentSlide - 1

    if (newSlide >= 0) {
      setCurrentSlide(newSlide)
    }
  }

  const maxSlides = Math.ceil(projects.length)

  const startIndex = currentSlide
  const endIndex = startIndex + 1

  return (
    <>
      <a.group
        scale={spring.scaleToProjects}
        position={spring.positionToProjects}
        rotation={spring.rotationToProjects}
      >
        <primitive
          object={projectsScene.scene}
          userData={{ viewPos: [-6.96, 2.72, 0] }}
          onClick={(e) => {
            e.stopPropagation()
            // Traverse the object hierarchy to find the clicked object
            const clickedObject = findClickedObject(e.object)

            if (!anyClick) {
              if (projectsScene.scene === clickedObject) {
                setClickedToProjects(clickedObject)
                setAnyClick(true)
              } else {
                setClickedToProjects(null)
                setAnyClick(false)
              }
            }
          }}
        >
          {clickedToProjects && (
            <Html
              transform
              wrapperClass='htmlScreen'
              distanceFactor={1.17}
              position={[
                -7.5749359130859375, 2.2805721282959, -0.4643821716308594,
              ]}
              rotation-order='YXZ'
              rotation-y={1.574}
              rotation-x={-0.19}
            >
              <div className='projects '>
                <h2 className='fmb-3'>Helped Secure </h2>
                <div className='row w-100 fmb-2'>
                  <div className='field w-42 fmr-2'>
                    <input
                      className='hover-flicker'
                      readOnly
                      disabled
                      value='Nasa government'
                    />
                  </div>
                  <div className='field w-30'>
                    <input
                      className='hover-flicker'
                      readOnly
                      disabled
                      value='Smartsheet'
                    />
                  </div>
                </div>
                <div className='row w-100 fmb-1'>
                  <div className='field w-14 fmr-2'>
                    <input
                      className='hover-flicker'
                      readOnly
                      disabled
                      value='IBM'
                    />
                  </div>
                  <div className='field w-28 fmr-2'>
                    <input
                      className='hover-flicker'
                      readOnly
                      disabled
                      value='Gocardless'
                    />
                  </div>
                  <div className='field w-27 fmr-2'>
                    <input
                      className='hover-flicker'
                      readOnly
                      disabled
                      value='HostGator'
                    />
                  </div>
                  <div className='field w-9'>
                    <input
                      className='hover-flicker'
                      readOnly
                      disabled
                      value='...'
                    />
                  </div>
                </div>

                <button
                  className='hover-flicker-1 btn-projects mt-1 '
                  onClick={() => {
                    setClickedToProjects(null)
                    setAnyClick(false)
                  }}
                >
                  Back
                </button>
              </div>
            </Html>
          )}
        </primitive>
      </a.group>
      {!anyClick && (
        <Html
          transform
          wrapperClass='tag'
          distanceFactor={1.17}
          rotation-y={1.565}
          position={[-7.5749359130859375, 2.805721282959, -0.4643821716308594]}
        >
          <div
            className={`tag-label ${start ? 'show-smoothly' : ''}`}
            onClick={() => {
              setClickedToProjects(projectsScene.scene)
              setAnyClick(true)
            }}
          >
            Bounties
          </div>
        </Html>
      )}
    </>
  )
}
