import { a } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'
import { FiLinkedin, FiMail } from 'react-icons/fi'
import './contact.scss'

export default function Contact({
  clickedToContact,
  setClickedToContact,
  anyClick,
  setAnyClick,
  findClickedObject,
  setClickedToCertificates,
  start,
}) {
  const contact = useGLTF('/models/phone.glb')

  return (
    <>
      <a.group>
        <primitive
          object={contact.scene}
          userData={{ viewPos: [0.194, 0.9, -6.26] }}
          onClick={(e) => {
            e.stopPropagation()
            // Traverse the object hierarchy to find the clicked object
            const clickedObject = findClickedObject(e.object)

            if (!anyClick) {
              if (contact.scene === clickedObject) {
                setClickedToContact(clickedObject)
                setAnyClick(true)
              } else {
                setClickedToContact(null)
                setAnyClick(false)
              }
            }
          }}
        >
          {clickedToContact && (
            <Html
              transform
              position={[0.123, 0.9018846750259399, -6.268909454345703]}
              rotation-x={-1.62}
              rotation-z={0.265}
              distanceFactor={1.7}
              wrapperClass='htmlScreen'
            >
              <div className='contact'>
                <div className='contact-links'>
                  <a
                    href='https://www.linkedin.com/in/badr-afilal'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='icon' // Add a class to style the icon container
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#1F619E' // Change background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '#7dc2f1AB' // Restore original color on hover out
                    }}
                  >
                    <FiLinkedin
                      color='white'
                      style={{
                        backgroundColor: '#7dc2f1AB',
                        display: 'block',
                        width: '100%',
                        fontSize: '8.5px',
                        paddingBottom: '2px',
                        paddingTop: '2px',

                        textDecoration: 'none', // Remove default link underline
                        color: 'white', // Set text color
                        borderRadius: '2%',
                      }}
                    />
                  </a>
                  <a
                    href='mailto:afilalbadr2@gmail.com'
                    className='icon' // Add a class to style the icon container
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#F14336' // Change background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '#7dc2f1AB' // Restore original color on hover out
                    }}
                  >
                    <FiMail
                      color='white'
                      style={{
                        backgroundColor: '#7dc2f1AB',
                        display: 'block',
                        width: '100%',
                        paddingBottom: '2px',
                        paddingTop: '2px',
                        marginTop: '2px',
                        textDecoration: 'none', // Remove default link underline
                        color: 'white', // Set text color
                        borderRadius: '2%',
                        fontSize: '8.5px',
                      }}
                    />
                  </a>
                </div>
                <div className='quit'>
                  <button
                    className='hamburger cross'
                    onClick={() => {
                      setClickedToContact(null)
                      setClickedToCertificates(null)
                      setAnyClick(false)
                    }}
                  >
                    <div className='line'></div>
                    <div className='line'></div>
                  </button>
                </div>
              </div>
            </Html>
          )}
          {!anyClick && (
            <Html
              transform
              wrapperClass='tag'
              distanceFactor={1.17}
              position={[0.194, 1.2018846750259399, -6.268909454345703]}
            >
              <div
                className={`tag-label ${start ? 'show-smoothly' : ''}`}
                onClick={() => {
                  setClickedToContact(contact.scene)
                  setAnyClick(true)
                }}
              >
                Contact
              </div>
            </Html>
          )}
        </primitive>
      </a.group>
    </>
  )
}
