export const skills = [
  {
    id: 0,
    category: 'Mobile App Penetration Testing',
    skills: [
      { skill: 'Android App Penetration Testing', level: '91' },
      { skill: 'iOS App Penetration Testing', level: '85' },
      { skill: 'Mobile Application Security Assessment', level: '95' },
    ],
  },
  {
    id: 1,
    category: 'Web Application Penetration Testing',
    skills: [
      { skill: 'OWASP Top 10 Vulnerabilities', level: '87' },
      { skill: 'Web Application Firewall (WAF) Bypass', level: '80' },
      { skill: 'Code Review', level: '97' },
    ],
  },
  {
    id: 2,
    category: 'API Penetration Testing',
    skills: [
      { skill: 'REST & GraphQL APIs Security Assessment', level: '88' },
      { skill: 'API Rate Limiting and Throttling', level: '90' },
      { skill: 'API Authentication Testing', level: '97' },
    ],
  },
  {
    id: 3,
    category: 'Network Security Assessment',
    skills: [
      { skill: 'Internal & External Network Security Assessment', level: '92' },
      { skill: 'Firewall Rule Review', level: '95' },
      {
        skill: 'Intrusion Detection and Prevention Systems (IDPS) Evaluation',
        level: '89',
      },
    ],
  },
  {
    id: 4,
    category: 'Active Directory Assessment',
    skills: [
      { skill: 'Active Directory Security Review', level: '95' },
      { skill: 'Privilege Escalation in Active Directory', level: '93' },
      { skill: 'Group Policy Analysis', level: '97' },
    ],
  },
  {
    id: 5,
    category: 'Windows Internals Analysis',
    skills: [
      { skill: 'Windows Registry & Event Log Analysis', level: '90' },
      { skill: 'File System Analysis', level: '88' },
      { skill: 'Process and Memory Analysis', level: '80' },
    ],
  },
  {
    id: 6,
    category: 'AV and EDR Bypass',
    skills: [
      { skill: 'Techniques for Bypassing Antivirus (AV)', level: '83' },
      {
        skill: 'Endpoint Detection and Response (EDR) Evasion',
        level: '67',
      },
      { skill: 'Code Obfuscation and Packing', level: '88' },
    ],
  },
  {
    id: 7,
    category: 'Memory Forensics',
    skills: [
      { skill: 'Volatility Framework', level: '90' },
      {
        skill: 'Detecting and Analyzing Dumps & Malware in Memory',
        level: '78',
      },
      {
        skill: 'Memory Artifacts and Indicators of Compromise (IOCs)',
        level: '75',
      },
    ],
  },
]

const certificates = [
  {
    id: 0,
    name: 'Cybersecurity (CC) Self-Paced Training',
    organization: 'ISC',
    deliveryDate: '19/03/2023',
    link: 'https://drive.google.com/file/d/1VqE2bdpROZ6eLmsev6ahIPTVydeFF-aI/view',
  },
  {
    id: 1,
    name: 'SOC Analyst',
    organization: 'TryHackMe',
    deliveryDate: '18/04/2023',
    link: 'https://drive.google.com/file/d/1f4_SBig_nkk_uM5UWAPXWBgyVDtwysE4/view',
  },
  {
    id: 2,
    name: 'Red Teaming',
    organization: 'TryHackMe',
    deliveryDate: '08/01/2023',
    link: 'https://drive.google.com/file/d/1sid7rAUXWlp_K8WCol6qa9-t9fRJtDEG/view',
  },
  {
    id: 3,
    name: 'AWS Cloud Quest: Cloud Practitioner',
    organization: 'Amazon Web Services',
    deliveryDate: '27/10/2022',
    link: 'https://www.credly.com/badges/649e4bab-27ff-46cd-be4d-5cc74615314a/linked_in_profile',
  },
  {
    id: 4,
    name: 'OPSWAT Network Security Associate (ONSA)',
    organization: 'OPSWAT',
    deliveryDate: '29/10/2022',
    link: 'https://www.credly.com/badges/a126f01e-dc1c-45b0-9974-fcad293e427f/linked_in_profile',
  },
  {
    id: 5,
    name: 'Comptia Pentest+',
    organization: 'TryHackMe',
    deliveryDate: '09/09/2022',
    link: 'https://drive.google.com/file/d/1UVDYiGys1SNz0XM-NzWUMTdZOut4JlO_/view',
  },
  {
    id: 6,
    name: 'Offensive Pentesting',
    organization: 'TryHackMe',
    deliveryDate: '06/09/2022',
    link: 'https://drive.google.com/file/d/1hyqoK_tOuQCkvn8OoWZzGIZmDPZp485x/view',
  },
  {
    id: 7,
    name: 'Oracle Cloud Infrastructure Foundations 2021 Certified Associate',
    organization: 'Oracle',
    deliveryDate: '28/02/2022',
    link: 'https://drive.google.com/file/d/1dhGTfT4OUeETJu5JN9tFQh3WtLtsyVR3/view',
  },
  {
    id: 8,
    name: 'Cyber Security Knowledge and Experience',
    organization: 'TryHackMe',
    deliveryDate: '24/02/2022',
    link: 'https://tryhackme-certificates.s3-eu-west-1.amazonaws.com/THM-NK3MXDZWBS.png',
  },
  {
    id: 9,
    name: 'CompTIA A+ (220-1001) Cert Prep 2: Microprocessing and RAM',
    organization: 'Linkedin',
    deliveryDate: '06/01/2022',
    link: 'https://drive.google.com/file/d/1_0opa7EdWmzd3fKo87LLiQ6IP_MKkGrH/view',
  },
  {
    id: 10,
    name: 'Network Administrator',
    organization: 'Linkedin',
    deliveryDate: '17/12/1021',
    link: 'https://drive.google.com/file/d/1gdJqMARVXcogZWLJWb2UkGnRhYDD-8cD/view',
  },
  {
    id: 11,
    name: 'Delf',
    organization: 'Institut français',
    deliveryDate: '01/01/2020',
    link: '',
  },
]

const projects = [
  {
    id: 0,
    title: 'High Availability with Proxmox',
    domain: 'Infrastructure',
  },
  {
    id: 2,
    title: 'Analyzing a Ransomware Attack',
    domain: 'Cybersecurity',
  },
  {
    id: 3,
    title: 'Bypass AV/EDR with Obfuscated Process Injection',
    domain: 'Cybersecurity',
  },
]

const credits = [
  {
    id: 0,
    task: 'Developed By',
    name: 'NAJLAE ABARGHACHE',
    link: 'mailto:abarghachenajlae@gmail.com',
    color: 'blue',
    buttonText: 'Contact',
  },
  {
    id: 2,
    task: 'Design & Models By',
    name: 'NAJLAE ABARGHACHE',
    link: 'mailto:abarghachenajlae@gmail.com',
    color: 'purple',
    buttonText: 'Contact',
  },
  {
    id: 3,
    task: 'Big Thanks To',
    name: 'Bruno SIMON',
    link: 'https://bruno-simon.com/',
    color: 'red',
    buttonText: 'MORE',
  },
]

export { certificates, projects, credits }
