import {
  Html,
  useGLTF,
  PresentationControls,
  Text,
  Text3D,
  useTexture,
} from '@react-three/drei'
import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { useRef, useState } from 'react'
import { useSpring } from 'react-spring'
import { useControls } from 'leva'
import Skills from './skills'
import Credits from './credits'
import About from './about'
import Contact from './contact'
import Bounties from './bounties'
import Certificates from './certificates'

export default function Experience({
  isMobile,
  isTablet,
  isDesktop,
  clickedToWorkpace,
  clickedToChillSpace,
  anyClick,
  setAnyClick,
  start,
}) {
  /** DEBUG */

  const { positionToSkillsDB, scaleToSkillsDB, rotationToSkillsDB } =
    useControls('Skills Pc', {
      positionToSkillsDB: {
        value: { x: 92, y: -23, z: 38 },
        step: 0.1,
        joystick: 'invertY',
      },
      scaleToSkillsDB: {
        value: 15,
        step: 0.5,
        min: 0,
        max: 55,
      },
      rotationToSkillsDB: {
        value: { x: 0, y: Math.PI / 7, z: 0 },
        step: 0.005,
        joystick: 'invertY',
      },
    })

  const { positionToProjectsDB, scaleToProjectsDB, rotationToProjectsDB } =
    useControls('Projects Pc', {
      positionToProjectsDB: {
        value: { x: 50, y: -20, z: 109 },
        step: 0.1,
        joystick: 'invertY',
      },
      scaleToProjectsDB: {
        value: 12,
        step: 0.5,
        min: 0,
        max: 55,
      },
      rotationToProjectsDB: {
        value: { x: 0, y: -1.09, z: 0 },
        step: 0.005,
        joystick: 'invertY',
      },
    })

  const { positionToCreditsDB, scaleToCreditsDB, rotationToCreditsDB } =
    useControls('Credits Pc', {
      positionToCreditsDB: {
        value: { x: 103, y: -20, z: 42 },
        step: 0.1,
        joystick: 'invertY',
      },
      scaleToCreditsDB: {
        value: 12,
        step: 0.5,
        min: 0,
        max: 55,
      },
      rotationToCreditsDB: {
        value: { x: 0, y: Math.PI / 7, z: 0 },
        step: 0.005,
        joystick: 'invertY',
      },
    })

  /**  Camera Position Initial Position **/
  const { cameraInitialPositionForMobile, cameraInitialPositionForDesktop } =
    useControls('Camera Initial Position', {
      cameraInitialPositionForDesktop: {
        value: { x: 5.5, y: 3.5, z: 11 },
        step: 0.1,
        joystick: 'invertY',
      },
      cameraInitialPositionForMobile: {
        value: { x: 13.5, y: 6, z: 24 },
        step: 0.1,
        joystick: 'invertY',
      },
    })

  /**Titles */
  const [titles, setTitles] = useState([
    'Security Analyst',
    'Cyber Consultant',
    'Cyber Engineer',
    'Purple Teamer',
    'CTF Player',
    'Pentester',
  ])
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0)

  const titleAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
    reset: true,
    config: {
      duration: 1500,
    },
    onRest: () => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length)
    },
    onReverseComplete: () => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length)
    },
  })

  /** Target Camera Position **/
  const targetCameraPositionForMobile = useRef([35.86, 9.26, 70])

  const targetCameraPositionForDesktop = useRef([24.16, 12.26, 50])

  /** Tv Camera Position **/
  const tvCameraPositionForMobile = useRef([
    3.2683334350585938, 2.8781912922859192, -1.752132034301758,
  ])

  const tvCameraPositionForIpad = useRef([
    3.2683334350585938, 2.8781912922859192, -4.952132034301758,
  ])

  const tvCameraPositionForDesktop = useRef([
    3.2683334350585938, 2.8781912922859192, -7.295509338378906,
  ])

  /** Certificates Wall Camera Position **/
  const certificatesWallCameraPositionForMobile = useRef([
    -2.5031333923339844, 3.4106106758117676, -0.9856886863708496,
  ])
  const certificatesWallCameraPositionForDesktop = useRef([
    -4.031333923339844, 3.4106106758117676, -0.9856886863708496,
  ])

  /** WorkSpace Camera Position **/
  const workspaceCameraPositionForMobile = useRef([
    10.031333923339844, 3.4106106758117676, 9.9856886863708496,
  ])

  /** ChillSpace Camera Position **/
  const chillspaceCameraPositionForMobile = useRef([
    9.6467795372009277, 7.538783264160156, 9.163888454437256,
  ])

  /** Phone Camera Position **/
  const phoneCameraPosition = useRef([
    0.18396759033203125, 2.8702536821365356, -6.255115509033203,
  ])

  const { camera } = useThree()

  const findClickedObject = (object) => {
    if (object.userData && object.userData.viewPos) {
      return object
    } else if (object.parent) {
      return findClickedObject(object.parent)
    }
    return null
  }

  const { nodes } = useGLTF('./models/room.glb')

  const lights = useGLTF('./models/lights.glb')
  const bakedFloor = useTexture('/textures/floor.jpg')
  const bakedWorkspace = useTexture('/textures/workspace.jpg')
  const bakedChillspace = useTexture('/textures/chillspace.jpg')

  const [clickedToSkills, setClickedToSkills] = useState(null)
  const [clickedToProjects, setClickedToProjects] = useState(null)
  const [clickedToCredits, setClickedToCredits] = useState(null)
  const [clickedToAbout, setClickedToAbout] = useState(null)
  const [clickedToCertificates, setClickedToCertificates] = useState(null)
  const [clickedToContact, setClickedToContact] = useState(null)

  const spring = useSpring({
    from: {
      scaleToSkills: [1, 1, 1],
      positionToSkills: [0, 0, 0],
      rotationToSkills: [0, 0, 0],

      scaleToProjects: [1, 1, 1],
      positionToProjects: [0, 0, 0],
      rotationToProjects: [0, 0, 0],

      scaleToAbout: [1, 1, 1],
      positionToAbout: [0, 0, 0],
      rotationToAbout: [0, 0, 0],

      scaleToContact: [1, 1, 1],
      positionToContact: [0, 0, 0],
      rotationToContact: [0, 0, 0],

      scaleToCredits: [1, 1, 1],
      positionToCredits: [0, 0, 0],
      rotationToCredits: [0, 0, 0],
    },
    to: {
      scaleToSkills: clickedToSkills
        ? [scaleToSkillsDB, scaleToSkillsDB, scaleToSkillsDB]
        : [1, 1, 1],
      rotationToSkills: clickedToSkills
        ? [rotationToSkillsDB.x, rotationToSkillsDB.y, rotationToSkillsDB.z]
        : [0, 0, 0],
      positionToSkills: clickedToSkills
        ? [positionToSkillsDB.x, positionToSkillsDB.y, positionToSkillsDB.z]
        : [0, 0, 0],

      scaleToProjects: clickedToProjects
        ? [scaleToProjectsDB, scaleToProjectsDB, scaleToProjectsDB]
        : [1, 1, 1],
      rotationToProjects: clickedToProjects
        ? [
            rotationToProjectsDB.x,
            rotationToProjectsDB.y,
            rotationToProjectsDB.z,
          ]
        : [0, 0, 0],
      positionToProjects: clickedToProjects
        ? [
            positionToProjectsDB.x,
            positionToProjectsDB.y,
            positionToProjectsDB.z,
          ]
        : [0, 0, 0],

      scaleToCredits: clickedToCredits
        ? [scaleToCreditsDB, scaleToCreditsDB, scaleToCreditsDB]
        : [1, 1, 1],
      rotationToCredits: clickedToCredits
        ? [rotationToCreditsDB.x, rotationToCreditsDB.y, rotationToCreditsDB.z]
        : [0, 0, 0],
      positionToCredits: clickedToCredits
        ? [positionToCreditsDB.x, positionToCreditsDB.y, positionToCreditsDB.z]
        : [0, 0, 0],
    },
    config: { mass: 3, tension: 300, friction: 50, precision: 0.0001 },
  })

  useFrame(() => {
    if (clickedToContact) {
      camera.position.lerp(
        new THREE.Vector3(...phoneCameraPosition.current),
        0.05
      )
      camera.lookAt(0.18396759033203125, 0.8702536821365356, -6.255115509033203)
    } else if (clickedToCertificates) {
      if (!isDesktop) {
        camera.position.lerp(
          new THREE.Vector3(...certificatesWallCameraPositionForMobile.current),
          0.05
        )
        camera.lookAt(
          -7.799570083618164,
          3.436150550842285,
          -0.7569475173950195
        )
      } else {
        camera.position.lerp(
          new THREE.Vector3(
            ...certificatesWallCameraPositionForDesktop.current
          ),
          0.05
        )
        camera.lookAt(
          -7.799570083618164,
          3.436150550842285,
          -0.7569475173950195
        )
      }
    } else if (clickedToSkills || clickedToProjects || clickedToCredits) {
      if (!isDesktop) {
        camera.position.lerp(
          new THREE.Vector3(...targetCameraPositionForMobile.current),
          0.05
        )
        camera.lookAt(0, 1.25, 0)
      } else {
        camera.position.lerp(
          new THREE.Vector3(...targetCameraPositionForDesktop.current),
          0.05
        )
      }
    } else if (clickedToAbout) {
      if (isMobile) {
        camera.position.lerp(
          new THREE.Vector3(...tvCameraPositionForMobile.current),
          0.05
        )

        camera.lookAt(
          3.9449615478515625,
          3.0736227989196777,
          -10.405017852783203
        )
      } else if (isTablet) {
        camera.position.lerp(
          new THREE.Vector3(...tvCameraPositionForIpad.current),
          0.05
        )
        camera.lookAt(
          3.9449615478515625,
          2.4736227989196777,
          -11.405017852783203
        )
      } else {
        camera.position.lerp(
          new THREE.Vector3(...tvCameraPositionForDesktop.current),
          0.05
        )
        camera.lookAt(
          3.9449615478515625,
          2.4736227989196777,
          -11.405017852783203
        )
      }
    } else if (clickedToWorkpace && !isDesktop) {
      camera.position.lerp(
        new THREE.Vector3(...workspaceCameraPositionForMobile.current),
        0.05
      )
      camera.lookAt(-6.151619911193848, 1.4547390937805176, -1.5514739751815796)
    } else if (clickedToChillSpace && !isDesktop) {
      camera.position.lerp(
        new THREE.Vector3(...chillspaceCameraPositionForMobile.current),
        0.05
      )
      camera.lookAt(1.8467795372009277, 2.4547390937805176, -9.381598472595215)
    } else {
      if (!isDesktop) {
        camera.position.lerp(
          new THREE.Vector3(
            cameraInitialPositionForMobile.x,
            cameraInitialPositionForMobile.y,
            cameraInitialPositionForMobile.z
          ),
          0.05
        )
        camera.lookAt(0.85, 3.55, 0)
      } else {
        camera.position.lerp(
          new THREE.Vector3(
            cameraInitialPositionForDesktop.x,
            cameraInitialPositionForDesktop.y,
            cameraInitialPositionForDesktop.z
          ),
          0.05
        )
        camera.lookAt(0, 1.25, 0)
      }
    }
  })

  return (
    <>
      <directionalLight castShadow position={[1, 2, 3]} intensity={1.2} />
      <ambientLight intensity={0.3} />

      <PresentationControls
        global
        polar={anyClick ? [0, 0] : [-0.25, 0.3]}
        azimuth={anyClick ? [0, 0] : [-1, 1]}
        config={{ mass: 2, tension: 400 }}
        snap={{ mass: 2.5, tension: 400 }}
      >
        <primitive object={lights.scene} />
        <mesh geometry={nodes.FLOOR.geometry}>
          <meshBasicMaterial map={bakedFloor} map-flipY={false} />
        </mesh>
        <mesh geometry={nodes.desk_table.geometry}>
          <meshBasicMaterial map={bakedWorkspace} map-flipY={false} />
        </mesh>
        <mesh geometry={nodes.Cube002.geometry}>
          <meshBasicMaterial map={bakedChillspace} map-flipY={false} />
        </mesh>

        <Skills
          clickedToSkills={clickedToSkills}
          setClickedToSkills={setClickedToSkills}
          anyClick={anyClick}
          setAnyClick={setAnyClick}
          spring={spring}
          findClickedObject={findClickedObject}
          start={start}
        />

        <Bounties
          clickedToProjects={clickedToProjects}
          setClickedToProjects={setClickedToProjects}
          anyClick={anyClick}
          setAnyClick={setAnyClick}
          spring={spring}
          findClickedObject={findClickedObject}
          start={start}
        />

        <Credits
          clickedToCredits={clickedToCredits}
          setClickedToCredits={setClickedToCredits}
          anyClick={anyClick}
          setAnyClick={setAnyClick}
          spring={spring}
          findClickedObject={findClickedObject}
          start={start}
        />

        <About
          clickedToAbout={clickedToAbout}
          setClickedToAbout={setClickedToAbout}
          anyClick={anyClick}
          setAnyClick={setAnyClick}
          findClickedObject={findClickedObject}
          start={start}
        />

        <Contact
          clickedToContact={clickedToContact}
          setClickedToContact={setClickedToContact}
          anyClick={anyClick}
          setAnyClick={setAnyClick}
          findClickedObject={findClickedObject}
          setClickedToCertificates={setClickedToCertificates}
          start={start}
        />

        <Certificates
          clickedToCertificates={clickedToCertificates}
          setClickedToCertificates={setClickedToCertificates}
          anyClick={anyClick}
          setAnyClick={setAnyClick}
          isTablet={isTablet}
          isMobile={isMobile}
          isDesktop={isDesktop}
          start={start}
        />

        <Text
          font='./Cyberverse.otf'
          fontSize={0.75}
          maxWidth={6}
          rotation-y={1.52}
          textAlign='center'
          letterSpacing={0.4}
          position={[-1.075458984375, 3.275943565368652, -8.118099212646484]}
          color='#4667EF'
          style={titleAnimation}
        >
          {titles[currentTitleIndex]}
          <meshBasicMaterial />
        </Text>
        <Text3D
          font='./Cyberverse_Regular.json'
          position={[
            -7.2513148593902588, 3.371106276512146, -4.0029425621032715,
          ]}
          size={0.435}
          height={0.15}
          curveSegments={1}
          bevelEnabled
          bevelThickness={0.03}
          bevelSize={0.01}
          bevelOffset={0}
          bevelSegments={5}
          letterSpacing={0.23}
        >
          Badr Afilal
          <meshLambertMaterial color={'#3e60d8'} />
        </Text3D>
      </PresentationControls>
    </>
  )
}
