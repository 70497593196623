import { a } from '@react-spring/three'
import { Html, useGLTF } from '@react-three/drei'
import { certificates } from './constants'
import { useState } from 'react'

export default function Certificates({
  clickedToCertificates,
  setClickedToCertificates,
  anyClick,
  setAnyClick,
  isTablet,
  isMobile,
  isDesktop,
  start,
}) {
  const certificatesScene = useGLTF('/models/certificates-wall.glb')

  const [currentSlide, setCurrentSlide] = useState(0)

  const handleCarouselNext = () => {
    // Calculate the new current slide
    const newSlide = currentSlide + 1

    // Check if there are certificates to display in the new slide
    if (newSlide < maxSlides) {
      setCurrentSlide(newSlide)
    }
  }

  const handleCarouselPrev = () => {
    // Calculate the new current slide
    const newSlide = currentSlide - 1

    // Check if there are certificates to display in the new slide
    if (newSlide >= 0) {
      setCurrentSlide(newSlide)
    }
  }

  // Calculate the maximum number of slides based on the number of certificates
  const maxSlides = Math.ceil(certificates.length / 3)

  // Calculate the start and end indexes for the current slide
  const startIndex = currentSlide * 3
  const endIndex = startIndex + 3
  return (
    <>
      <a.group>
        <primitive
          object={certificatesScene.scene}
          visible={false}
          userData={{
            viewPos: [-7.8, 3.436, -0.75],
          }}
        >
          {clickedToCertificates && (
            <Html
              transform
              wrapperClass='htmlScreen'
              distanceFactor={1.17}
              position={[
                -7.799570083618164, 3.536150550842285, -0.7569475173950195,
              ]}
              rotation-y={1.62}
            >
              <div className='certificates'>
                {isTablet || isMobile ? (
                  <>
                    <div className='carousel-controls mb-2'>
                      <div
                        className={`carousel-control-btn hamburgerA arrow-previous mr-1 ml-1 ${
                          currentSlide === 0 ? 'disabled' : ''
                        }`}
                        disabled={currentSlide === 0}
                        onClick={handleCarouselPrev}
                      >
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                      </div>

                      <div
                        className={`carousel-control-btn hamburgerA arrow-next ${
                          currentSlide === maxSlides - 1 ? 'disabled' : ''
                        }`}
                        disabled={currentSlide === maxSlides - 1}
                        onClick={handleCarouselNext}
                      >
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                      </div>
                    </div>

                    <div className='carousel-container'>
                      {certificates
                        .slice(startIndex, endIndex)
                        .map((certificate, certificateIndex) => (
                          <div
                            key={certificateIndex}
                            className='certificate glow flex column p-1 mb-1 hoverable blue'
                          >
                            <div className='flex row p-1 pb-2'>
                              <strong>{certificate.name}</strong>
                            </div>
                            <div className='row w-100 pt-1'>
                              <div className='field w-58'>
                                <input
                                  readOnly
                                  disabled
                                  type='text'
                                  value={certificate.organization}
                                  pattern='\w+'
                                />
                              </div>
                              <div className='field w-38'>
                                <input
                                  readOnly
                                  disabled
                                  type='text'
                                  value={certificate.deliveryDate}
                                  pattern='\w+'
                                />
                              </div>
                            </div>
                            {certificate.link !== '' && (
                              <a href={certificate.link} target='_blank'>
                                <div className='flex row module right-bottom reveal mt-2'>
                                  <button className='button-glow blue fill'>
                                    MORE
                                  </button>
                                </div>
                              </a>
                            )}
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className='certificates-container'>
                    {certificates.map((certificate, certificateIndex) => (
                      <div
                        key={certificateIndex}
                        className='certificate glow flex column p-1 hoverable blue'
                      >
                        <div className='flex row p-1 pb-2'>
                          <strong>{certificate.name}</strong>
                        </div>
                        <div className='row w-100 pt-1'>
                          <div className='field w-58'>
                            <input
                              readOnly
                              disabled
                              type='text'
                              value={certificate.organization}
                              pattern='\w+'
                            />
                          </div>
                          <div className='field w-38'>
                            <input
                              readOnly
                              disabled
                              type='text'
                              value={certificate.deliveryDate}
                              pattern='\w+'
                            />
                          </div>
                        </div>
                        {certificate.link !== '' && (
                          <a href={certificate.link} target='_blank'>
                            <div className='flex row module right-bottom reveal mt-2'>
                              <button className='button-glow blue fill'>
                                MORE
                              </button>
                            </div>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                <button
                  className={`hover-flicker-1 btn-skew mb-1 mt-1 ${
                    isDesktop ? 'mr-2' : ''
                  }`}
                  onClick={() => {
                    setClickedToCertificates(null)
                    setAnyClick(false)
                  }}
                >
                  Back
                </button>
              </div>
            </Html>
          )}
          {!anyClick && (
            <Html
              transform
              wrapperClass='tag'
              distanceFactor={1.17}
              rotation-y={1.565}
              position={[
                -7.799570083618164, 3.436150550842285, -0.7569475173950195,
              ]}
            >
              <div
                className={`tag-label ${start ? 'show-smoothly' : ''}`}
                onClick={() => {
                  setClickedToCertificates(certificatesScene.scene)
                  setAnyClick(true)
                }}
              >
                Certificates
              </div>
            </Html>
          )}
        </primitive>
      </a.group>
    </>
  )
}
