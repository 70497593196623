import { Canvas } from '@react-three/fiber'
import { Suspense, useEffect, useRef, useState } from 'react'
import Experience from './Experience'
import { LoadingScreen } from './LoadingScreen'
import { MdMusicNote, MdMusicOff } from 'react-icons/md'

const clickAudio = new Audio('./audio/hit.mp3')

function App() {
  const [start, setStart] = useState(false)

  const [anyClick, setAnyClick] = useState(false)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const music = useRef(new Audio('./audio/hacking-track.mp3'))
  const [musicLoaded, setMusicLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  music.current.loop = true
  music.current.volume = 0.3

  useEffect(() => {
    // Listen for the "canplaythrough" event to determine when audio has loaded
    clickAudio.addEventListener('canplaythrough', () => {
      setAudioLoaded(true)
    })
    music.current.addEventListener('canplaythrough', () => {
      setMusicLoaded(true)
    })
  }, [])

  // Function to enable touch-action
  function enableTouchAction() {
    document.documentElement.style.touchAction = 'auto'
  }

  // Function to disable touch-action
  function disableTouchAction() {
    document.documentElement.style.touchAction = 'none'
  }
  // Use useEffect to enable/disable touch-action based on anyClick
  useEffect(() => {
    if (anyClick) {
      enableTouchAction()
    } else {
      disableTouchAction()
    }
  }, [anyClick])
  const [clickedToWorkpace, setClickedToWorkpace] = useState(false)
  const [clickedToChillSpace, setClickedToChillSpace] = useState(false)

  const [isMobile, setIsMobile] = useState(false)

  const [isTablet, setIsTablet] = useState(false)

  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 450)

      setIsTablet(window.innerWidth <= 900 && window.innerWidth > 450)

      setIsDesktop(window.innerWidth > 900)
    }

    handleResize() // Initial check

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (start && audioLoaded && musicLoaded) {
      clickAudio.play()
      setTimeout(() => {
        if (music.current.paused) {
          setIsPlaying(true)
        }
      }, 4000)
    }
  }, [start])

  useEffect(() => {
    if (isPlaying) {
      clickAudio.play()
      music.current.play()
      console.log('Playing the audio')
    } else {
      clickAudio.play()
      music.current.pause()
      console.log('Pausing the audio')
    }
  }, [isPlaying])

  return (
    <>
      {isMobile && !clickedToWorkpace && !clickedToChillSpace && !anyClick && (
        <div className={`mobile-controls ${start ? 'show-smoothly' : ''}`}>
          <div className='child-control'>
            <div
              className='hamburgerC arrow-previous float-left'
              onClick={() => {
                setClickedToWorkpace(true)
              }}
            >
              <div className='line'></div>
              <div className='line'></div>
              <div className='line'></div>
            </div>
          </div>
          <div className='child-control'>
            <div
              className='hamburgerC arrow-next float-right'
              onClick={() => {
                setClickedToChillSpace(true)
              }}
            >
              <div className='line'></div>
              <div className='line'></div>
              <div className='line'></div>
            </div>
          </div>
        </div>
      )}

      {clickedToWorkpace && !anyClick && (
        <div className='mobile-quit'>
          <button
            className='hamburgerC crossC'
            onClick={() => {
              setClickedToWorkpace(false)
            }}
          >
            <div className='line'></div>
            <div className='line'></div>
          </button>
        </div>
      )}

      {clickedToChillSpace && !anyClick && (
        <div className='mobile-quit'>
          <button
            className='hamburgerC crossC'
            onClick={() => {
              setClickedToChillSpace(false)
            }}
          >
            <div className='line'></div>
            <div className='line'></div>
          </button>
        </div>
      )}
      <>
        <Canvas
          shadows
          camera={{
            fov: 35,
            near: 0.1,
            far: 2000,
            position: [5.5, 4, 11],
          }}
        >
          <Suspense fallback={null}>
            <Experience
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              clickedToWorkpace={clickedToWorkpace}
              clickedToChillSpace={clickedToChillSpace}
              anyClick={anyClick}
              setAnyClick={setAnyClick}
              start={start}
            />
          </Suspense>
        </Canvas>
        <button className='music' onClick={() => setIsPlaying(!isPlaying)}>
          {isPlaying ? (
            <MdMusicNote className='music-icon' />
          ) : (
            <MdMusicOff className='music-icon' />
          )}
        </button>
      </>
      <LoadingScreen
        started={start}
        onStarted={() => setStart(true)}
        audioLoaded={audioLoaded}
        musicLoaded={musicLoaded}
      />
    </>
  )
}

export default App
